document.getElementById('js-hamburger-button').onclick = toggleMenu
function toggleMenu () {
  document.getElementById('js-menu').classList.toggle('is-active')
  document.getElementById('js-hamburger-button').classList.toggle('is-active')
}

$(document).ready(function() {
    $('.nav-button').on('click', function(){
    	$(this).toggleClass('is-active');
    	$(this).parent().next('.child-list').toggleClass('is-active');
    });

    $('.banner-slider').flickity({
	  // options
	  cellAlign: 'left',
	  contain: true,
	  prevNextButtons: false,
    autoPlay: true
	});

    $('.dropdown-button').on('click', function(){
      $(this).toggleClass('is-active');
      $(this).parent().parent().parent().next('.drop-down-content').toggleClass('is-active');
    });
});
